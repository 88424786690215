/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@hiyllo/ux/theme';
import { HiylloTokyoThemeDark } from '@hiyllo/ux/tokyo-theme';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Blog_JSApi } from './blog/articles/js-api';
import { FontAwesomeIconCtx } from '@hiyllo/ux/platform'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlogListPage } from './blog/page';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* @ts-expect-error --- */}
    <FontAwesomeIconCtx.Provider value={FontAwesomeIcon}>
    <ThemeProvider theme={HiylloTokyoThemeDark}>
      <BrowserRouter>
        <Switch>
          <Route path="/blog/js-api">
            <Blog_JSApi/>
          </Route>
          <Route path="/blog">
            <BlogListPage/>
          </Route>
          <Route>
            <App/>
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
    </FontAwesomeIconCtx.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
