/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { styled } from "@hiyllo/ux/styled";
import './App.css';
import { Logo } from './logo';
import { motion } from 'framer-motion';
import { Typography } from '@hiyllo/ux/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBluesky, faInstagram, faLinkedin, faThreads, faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons';
import { Helmet } from "react-helmet";

const Container = styled<"div", {$show:boolean}>("div", ({$theme, $show}) => ({
  opacity: $show ? 1 : 0,
  transition: "opacity 0.3s",
  // background: $theme.background1,
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "hiyllo, sans-serif",
  gap: 25,
  color: $theme.foreground,
  background: $theme.background1,
  overflow: "hidden"
}));

function App() {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  return (
    <Container $show={show}>
      <Helmet>
        <link rel="canonical" href="https://www.hiyllo.com" />
      </Helmet>
      <motion.div
        initial={{opacity:0, translateX: -50}}
        animate={{opacity:1, translateX:0}}
        transition={{delay:5, duration: 1}}
        style={{position:"absolute", zIndex:10, top: 50, left: 50}}
      >
        <div style={{maxWidth: Math.min(500, window.innerWidth - 150)}}>
          <Typography.Header>
            Hiyllo
          </Typography.Header>
          <div style={{height:10}}/>
          <Typography.SubHeader>
            Products that give you more time to focus on what matters most.
          </Typography.SubHeader>
          <div style={{height:10}}/>
          <div style={{fontSize:20}}>
            We're a software company based in Princeton, NJ with a team of ~a dozen people.
          </div>
          <div style={{height:10}}/>
          <div style={{fontSize:20}}>
            We made <a href="https://www.hiyllo.work" target="_blank" rel="noreferrer" style={{color:"white"}}><div style={{display:"inline-block", height:"1em", overflow:"hidden"}}><img src="/work-s.png" style={{height:"0.75em", paddingTop:"0.3em"}}/></div> Hiyllo Work</a>,
            a platform that brings everything your team needs all into one place.
          </div>
          <div style={{height:10}}/>
          <div style={{fontSize:20}}>
            We&apos;re building <a href="https://www.hiyllo.work" target="_blank" rel="noreferrer" style={{color:"white"}}><div style={{display:"inline-block", height:"1em", overflow:"hidden"}}><img src="/support-s.png" style={{height:"0.75em", paddingTop:"0.3em"}}/></div> Hiyllo Support</a>,
            a down-to-earth support center, with a focus on simplicity for admins, agents, and customers.
          </div>

          <div style={{height:20}}/>
          <div style={{display:"flex", flexDirection:"column", gap:10}}>
            <a href="https://www.linkedin.com/company/hiylloinc" style={{color:"white"}} target="_blank" rel="noreferrer">
              <div style={{ display:"flex", flexDirection:"row", alignItems:"center", gap: 7.5, fontSize: 15 }}>
                <FontAwesomeIcon icon={faLinkedin}/> @hiylloinc <FontAwesomeIcon icon={faExternalLinkAlt}/>
              </div>
            </a>
            <a href="https://www.instagram.com/hiylloinc/" style={{color:"white"}} target="_blank" rel="noreferrer">
              <div style={{ display:"flex", flexDirection:"row", alignItems:"center", gap: 7.5, fontSize: 15 }}>
                <FontAwesomeIcon icon={faInstagram}/> @hiylloinc <FontAwesomeIcon icon={faExternalLinkAlt}/>
              </div>
            </a>
            <a href="https://x.com/hiylloinc" style={{color:"white"}} target="_blank" rel="noreferrer">
              <div style={{ display:"flex", flexDirection:"row", alignItems:"center", gap: 7.5, fontSize: 15 }}>
                <FontAwesomeIcon icon={faTwitter}/>/<FontAwesomeIcon icon={faXTwitter}/> @hiylloinc <FontAwesomeIcon icon={faExternalLinkAlt}/>
              </div>
            </a>
            <a href="https://www.threads.net/@hiylloinc" style={{color:"white"}} target="_blank" rel="noreferrer">
              <div style={{ display:"flex", flexDirection:"row", alignItems:"center", gap: 7.5, fontSize: 15 }}>
                <FontAwesomeIcon icon={faThreads}/> @hiylloinc <FontAwesomeIcon icon={faExternalLinkAlt}/>
              </div>
            </a>
            <a href="https://bsky.app/profile/hiyllo.com" style={{color:"white"}} target="_blank" rel="noreferrer">
              <div style={{ display:"flex", flexDirection:"row", alignItems:"center", gap: 7.5, fontSize: 15 }}>
                <FontAwesomeIcon icon={faBluesky}/> @hiylloinc <FontAwesomeIcon icon={faExternalLinkAlt}/>
              </div>
            </a>
            <a href="mailto:support@hiyllo.com" style={{color:"white"}} target="_blank" rel="noreferrer">
              <div style={{ display:"flex", flexDirection:"row", alignItems:"center", gap: 7.5, fontSize: 15 }}>
                <FontAwesomeIcon icon={faEnvelope}/> support@hiyllo.com <FontAwesomeIcon icon={faExternalLinkAlt}/>
              </div>
            </a>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{opacity: 1}}
        animate={{opacity: window.innerHeight < window.innerWidth ? 1 : 0}}
        transition={{delay:5, duration: 1}}
        style={{position:"absolute", top:0, left:0, height:"100%", width:"100%"}}
      >
        <Logo/>
      </motion.div>
      <motion.div
        initial={{opacity:0, translateX: -50}}
        animate={{opacity:1, translateX:0}}
        transition={{delay:5, duration: 1}}
        style={{position:"absolute", bottom: 50, left: 50, display:"flex", flexDirection:"row", alignItems:"center", gap: 7.5}}
      >
        <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png" style={{height: 15, width: 15}}/>
        &copy; Hiyllo Inc, 2025
      </motion.div>
      <motion.div
        initial={{opacity:0, translateX:10}}
        animate={{opacity:1, translateX:0}}
        transition={{delay:5, duration: 1}}
        style={{position:"absolute", bottom: 50, right: 50}}
      >
        <div>
          <a href="https://www.hiyllo.work" target="_blank" rel="noreferrer">
            <img src="/work-logo-s.png" style={{height:50}}/>
          </a>
        </div>
      </motion.div>
    </Container>
  );
}

export default App;
